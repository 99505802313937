<template>
  <el-row :gutter="40" class="space-y-1">
    <el-col>
      <el-form
        class="action-container space-x-1"
        @submit.native.prevent="fetch"
      >
        <el-button type="info" @click="$router.push('/permission/create')"
          >添加权限</el-button
        >
        <el-input
          placeholder="请搜索关键词"
          class="action-input"
          v-model="paginate.keywords"
        />
        <el-button type="primary" class="action-button" native-type="submit"
          >搜索</el-button
        >
        <el-button type="basic" @click="resetSearch">重置</el-button>
      </el-form>
    </el-col>
    <el-col :md="12">
      <div class="space-y-1">
        <el-table :data="permissions" border style="width: 100%">
          <el-table-column prop="id" label="ID" width="80"> </el-table-column>
          <el-table-column prop="name" label="权限名"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="$router.push(`/permission/edit/${scope.row.id}`)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleRemove(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="paginate.page"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="paginate.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paginate.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-col>
    <el-col :md="12">
      <el-tree
        :data="treePermissions"
        :props="{ label: 'name' }"
        default-expand-all
        :render-content="renderContent"
      ></el-tree>
    </el-col>
  </el-row>
</template>

<script>
import { data2tree } from '../../utils/tree'
export default {
  data() {
    return {
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
      },
      permissions: [],
      treePermissions: [],
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      const res = await this.$http.request({
        url: 'permissions',
        method: 'get',
        params: this.paginate,
      })
      this.permissions = res.data.data
      this.paginate.total = res.data.meta.total

      this.getTreepermission()
    },
    async getTreepermission() {
      const res = await this.$http.get('permissions?paginate=0&order=asc')
      this.treePermissions = data2tree(res.data)
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page) {
      this.paginate.page = page
      this.fetch()
    },
    handleRemove(row) {
      this.$confirm(`此操作将永久删除"${row.name}", 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`permissions/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    renderContent(h, { node, data }) {
      return (
        <span class="custom-tree-node">
          <span>{node.label}</span>
          <span>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              on-click={() => this.$router.push(`/permission/edit/${data.id}`)}
            ></el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              on-click={() => this.handleRemove(data)}
            ></el-button>
          </span>
        </span>
      )
    },
  },
}
</script>
